<template>
  <BaseModal
    ref="modal"
    title="Melding naar eigenaar"
    max-width="tw-max-w-xl"
    @hide="hide"
  >
    <FormulateForm
      name="pictureNotification"
      @submit="send"
    >
      <p>De eigenaar wordt via e-mail op de hoogte gebracht dat de gepubliceerde foto's geraadpleegd kunnen worden via het klantenportaal.</p>
      <FormulateInput
        type="checkbox"
        name="pictures_made_by_partner"
        label="Foto's gemaakt door externe fotograaf"
        label-class="tw-text-xs tw-mb-0.5"
        outer-class="tw-m-0"
      />
      <FormulateInput
        type="submit"
        label-class="tw-text-xs tw-mb-0.5"
      >
        E-mail verzenden
      </FormulateInput>
    </FormulateForm>
  </BaseModal>
</template>

<script>
import { sendPropertyPicturesAvailableEmail, updateProperty } from '@/services/properties'
import { createLog } from '@/services/apiService'
import { mapActions } from 'vuex'
import { errorModal, successModal } from '@/modalMessages'
export default {
  props: {
    propertyId: {
      type: Number,
      required: true
    },
    isAutomatedQuestion: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      logSendingDone: false // To make sure the logging is only done once is combination with hide()
    }
  },
  methods: {
    ...mapActions('properties', ['loadProperty']),
    async send (values) {
      try {
        const response = await sendPropertyPicturesAvailableEmail(this.propertyId, values)
        await this.logSending('sent')
        successModal('De e-mail wordt verstuurd ...')
        this.$refs.modal.hide()
        return response
      } catch (error) {
        console.error(error)
        errorModal('Er ging iets mis bij het verzenden van de e-mail.')
      }
    },
    show () {
      this.$refs.modal.show()
    },
    async hide () {
      const response = await this.logSending('hide')
      return response
    },
    async logSending (type) {
      if (this.logSendingDone) return
      if (!this.isAutomatedQuestion && type === 'hide') return
      if (type === 'hide') type = 'rejected_by_collaborator'
      if (type === 'sent') type = this.isAutomatedQuestion ? 'accepted_by_collaborator' : 'button_click'
      const payload = {
        action_id: 35,
        content_object_id: this.propertyId,
        content_object_class: 'property',
        meta: { type }
      }
      const response = await createLog(payload)
      if (!this.isAutomatedQuestion) return response
      const updateResponse = updateProperty(this.propertyId, { meta: { send_pictures_availability_question_previously_asked: true } })
      await this.loadProperty(this.propertyId)
      this.logSendingDone = true
      return [updateResponse, response]
    }
  }
}
</script>
