<template>
  <div ref="dropzone" @click="triggerUploader">
    <slot name="droparea" :loading="isLoading">
      <div
        :class="[
          'tw-grid tw-place-items-center tw-border tw-border-dashed tw-border-tg-color tw-rounded',
          { 'tw-cursor-pointer tw-opacity-60 hover:tw-opacity-100': !isLoading }
        ]"
      >
        <div class="tw-px-2 tw-py-4 tw-pointer-events-none tw-text-center">
          <i :class="['tw-m-2 fal fa-2x', isLoading ? 'fa-spinner-third fa-spin' : 'fa-file-upload']" />
          <br />
          <span class="tw-font-semibold tw-text-xs">
            {{ isLoading ? labels.loading : labels.static }}
          </span>
        </div>
      </div>
    </slot>
  </div>
</template>

<script>
import Dropzone from 'dropzone'
import { errorModal, warningModal } from '@/modalMessages'
import { getCookie } from '@/utils/helpers'

export default {
  name: 'FileDropzone',
  props: {
    url: {
      type: String,
      required: true
    },
    isEntityFileUpload: {
      type: Boolean,
      default: false
    },
    method: {
      type: String,
      default: 'post'
    },
    paramName: {
      // Specifies the param/field name to be used in the form payload of the file
      type: String,
      default: 'file'
    },
    maxFiles: {
      type: Number,
      default: null
    },
    labels: {
      type: Object,
      default () {
        return {
          loading: 'Bestanden uploaden',
          static: 'Sleep bestanden of klik om verkenner te openen'
        }
      }
    },
    imageUploadOnly: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isLoading: false
    }
  },
  watch: {
    url () {
      this.dropzone.options.url = this.url
    }
  },
  mounted () {
    this.setup()
  },
  methods: {
    setup () {
      const options = {
        url: this.url,
        method: this.method,
        paramName: this.paramName,
        maxFiles: this.maxFiles,
        headers: {
          'X-CSRFToken': getCookie('csrftoken')
        },
        previewTemplate: '<div></div>' // To not show the file preview
      }
      if (this.imageUploadOnly) {
        options.acceptedFiles = '.jpeg,.jpg,.png,.gif'
      }
      this.dropzone = new Dropzone(
        this.$refs.dropzone,
        options
      )

      this.dropzone.on('addedfile', () => {
        this.isLoading = true
      })

      this.dropzone.on('success', (file, response) => {
        if (this.isEntityFileUpload) this.$emit('file-uploaded', response)
        else this.$emit('file-uploaded', file.name)
      })

      this.dropzone.on('queuecomplete', () => {
        this.isLoading = false
        this.dropzone.removeAllFiles(true) // Empty the queue after uploading the files
        this.$emit('all-uploaded')
      })

      this.dropzone.on('error', (file, error) => {
        console.error(error)
        this.isLoading = false
        // dropzone error
        if (error === 'You can not upload any more files.') {
          warningModal(`Er kunnen maximaal ${this.maxFiles} bestanden in één keer geüpload worden`)
        } else if (error === "You can't upload files of this type.") {
          warningModal("Enkel foto's met het formaat .jpg, .jpeg of .png kunnen worden geüpload.")
        } else {
          errorModal(`Er ging iets fout bij het uploaden van het bestand '${file.name}': ${error.error}'. Gelieve nogmaals te proberen.`)
        }
      })
    },
    triggerUploader () {
      // To always open the uploader on click.
      this.$refs.dropzone.click()
    }
  }
}
</script>
