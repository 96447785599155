<template>
  <div class="modal" ref="modal" :style="{display: display}">

    <div class="cont">
      <!-- The Close Button -->
      <span @click="hide" class="close">&times;</span>

      <!-- Modal Content (The video) -->
      <iframe width="853" height="480"
              :src="embeddedVideoUrl" class="modal-content">
      </iframe>
    </div>

    <!-- Modal Caption (Image Text) -->
  </div>
</template>

<script>
import $ from 'jquery'

export default {
  name: 'VideoPopUp',
  props: ['videoUrl'],
  data () {
    return {
      display: 'none',
      picture: {
        url: '',
        tags: [],
        order: 0,
        public: false,
        is_main_picture: false
      }
    }
  },

  methods: {
    show () {
      this.display = 'block'
    },
    hide () {
      this.display = 'none'
      $('.')
    },
    parseYouTubeVideoId (url) {
      const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/
      const match = url.match(regExp)
      return (match && match[7].length === 11) ? match[7] : false
    }
  },
  computed: {
    embeddedVideoUrl () {
      if (this.display === 'block') {
        return `https://www.youtube.com/embed/${this.parseYouTubeVideoId(this.videoUrl)}`
      } else {
        return ''
      }
    }
  }
}
</script>

<style scoped>
  /* The Modal (background) */
  .modal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    padding-top: 100px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.7); /* Black w/ opacity */
  }

  /* Modal Content (Image) */
  .modal-content {
    margin: auto;
    display: block;
    width: inherit;
    height: 80vh;
    max-width: 60%;
  }

  /* Caption of Modal Image (Image Text) - Same Width as the Image */
  #caption {
    margin: auto;
    display: block;
    width: 80%;
    max-width: 700px;
    text-align: center;
    color: #ccc;
    padding: 10px 0;
    height: 150px;
  }

  /* Add Animation - Zoom in the Modal */
  .modal-content, #caption {
    animation-name: zoom;
    animation-duration: 0.4s;
  }

  @keyframes zoom {
    from {
      transform: scale(0)
    }
    to {
      transform: scale(1)
    }
  }

  .cont {
    position: relative;
    display: inline-block;
    left: 50%;
    transform: translate(-50%);
    width: 100%;
  }

  /* The Close Button */
  .close {
    top: -4%;
    right: 18%;
    font-size: 40px;
  }

  .previous {
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }

  .next {
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }

  .cont span:not(.close) {
    font-size: 60px;
  }

  .previous,
  .next {
    height: 100%;
    width: 20%;
  }

  .next-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  .previous-icon {
    float: right;
    top: 50%;
    position: absolute;
    right: 1%;
    transform: translateY(-50%);
  }

  .close,
  .previous,
  .next {
    position: absolute;
    color: #f1f1f1;
    font-weight: bold;
    transition: 0.3s;
    opacity: .7;
  }

  .close:hover,
  .close:focus,
  .previous:hover,
  .previous:focus,
  .next:hover,
  .next:focus {
    color: #bbb;
    text-decoration: none;
    cursor: pointer;
  }

  /* 100% Image Width on Smaller Screens */
  @media only screen and (max-width: 700px) {
    .modal-content {
      width: 100%;
    }
  }
</style>
