<template>
  <div>
    <div class="btn-group margin-bottom-20">
      <button class="btn btn-default" @click="showEditModal">
        Toevoegen
      </button>
    </div>
    <EditModal ref="editModal" :config="editConfig" :object="{}" @save="handleSave" />
    <div class="row" v-if="mediaObjects">
      <div class="col-lg-12">
        <table class="table table-hover" style="width: 100%;">
          <thead>
          <tr>
            <th>Type</th>
            <th>Publiek</th>
            <th>Leverancier</th>
            <th>Acties</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="mediaObject in mediaObjects" :key="mediaObject.id">
            <td>
              <a @click="previewMediaObject(mediaObject)">{{ mediaObject.type_display }}</a>
              <VideoPopUp
                :ref="'videoPopUp' + mediaObject.id"
                v-if="mediaObject.type === 0"
                :videoUrl="mediaObject.identifier"
              />
            </td>
            <td>
              <span v-if="mediaObject.public">Ja</span>
              <span v-else>Nee</span>
            </td>
            <td>
              {{ mediaObject.supplier_display }}
            </td>
            <td>
              <button
                :class="['btn btn-xs', mediaObject.public ? 'btn-info' : 'btn-default']"
                @click="updateMedia(mediaObject.id)"
              >
                <i class="fa fa-globe" />
              </button>
              <button
                class="btn btn-danger btn-xs"
                @click="deleteMedia(mediaObject.id)"
              >
                <i class="fa fa-trash" />
              </button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import VideoPopUp from '@/components/iam/VideoPopUp'
import EditModal from '@/components/iam/EditModal'
import { errorModal, questionModal, successModal } from '@/modalMessages'

export default {
  name: 'EntityMedia',
  components: {
    VideoPopUp,
    EditModal
  },
  computed: {
    ...mapGetters('properties', ['getEntityMedia']),

    entityType () {
      return this.$route.meta.entity_type
    },
    entityId () {
      return this.$route.params.id
    },
    mediaObjects () {
      const payload = {
        id: this.entityId,
        entityType: this.entityType
      }
      return this.getEntityMedia(payload)
    },
    editConfig () {
      return {
        fields: [
          {
            value: 'type',
            label: 'Type',
            placeholder: '',
            type: 'select',
            options: { 0: 'Video', 1: 'Virtuele tour', 2: 'Plattegrond', 3: '3D render', 4: 'Logo Project', 5: 'Verticale video' }
          },
          {
            value: 'identifier',
            label: 'Verwijzing',
            placeholder: 'Url of code',
            type: 'text'
          },
          {
            value: 'file',
            label: 'Upload',
            placeholder: '',
            type: 'upload'
          },
          {
            value: 'supplier',
            label: 'Leverancier',
            placeholder: '',
            type: 'select',
            required: false,
            options: { 0: 'Around.media', 1: 'Zibber', 2: 'Blinqlab', 3: 'YouTube', 4: 'NodalView', 5: 'Matterport', 6: 'Walky' }
          }
        ],
        title: 'Media toevoegen'
      }
    }
  },
  created () {
    this.loadEntityMedia({ id: this.entityId, entityType: this.entityType })
  },
  methods: {
    ...mapActions('properties', [
      'loadEntityMedia',
      'saveEntityMedia',
      'updateEntityMedia',
      'deleteEntityMedia'
    ]),
    handleSave (values) {
      const payload = values
      payload.entity = this.entityId
      payload.entityType = this.entityType
      this.saveEntityMedia(payload).then(
        () => successModal('Het media object werd succesvol toegevoegd'),
        () => errorModal('Kon media object niet toevoegen, gelieve opnieuw te proberen')
      )
      this.$refs.editModal.hide()
      this.$refs.editModal.clear()
    },
    showEditModal () {
      this.$refs.editModal.show()
    },
    updateMedia (id) {
      const payload = {
        entityId: this.entityId,
        entityType: this.entityType,
        mediaId: id
      }
      this.updateEntityMedia(payload)
    },
    async deleteMedia (id) {
      const result = await questionModal('Weet je zeker dat je dit media object wil verwijderen?')
      if (!result.value) return
      const payload = {
        entityId: this.entityId,
        entityType: this.entityType,
        mediaId: id
      }
      this.deleteEntityMedia(payload).then(
        () => successModal('Het media object werd succesvol verwijderd'),
        () => errorModal('Kon media object niet verwijderen, gelieve opnieuw te proberen')
      )
    },
    previewMediaObject (obj) {
      switch (obj.type) {
        case 0:
          this.$refs[`videoPopUp${obj.id}`][0].show()
          break
        default:
          if (obj.identifier) {
            window.open(obj.identifier, '_blank')
          } else {
            window.open(obj.file_url, '_blank')
          }
      }
    }
  }
}
</script>

<style scoped>
  .file {
    position: relative;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
    transition: all 0.2s ease;
  }

  .file:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 10px rgba(0, 0, 0, 0.2);
  }

  .file p {
    margin-bottom: 0;
  }

  .file p:last-child:not(:empty) {
    margin-top: 5px;
  }

  .label {
    display: inline-block;
    margin: 1px 0;
  }

  label {
    font-weight: 400;
  }

  .drag-icon {
    position: absolute;
    bottom: 10px;
    right: 10px;
  }

  .notransition {
    /*CSS transitions*/
    -o-transition-property: none !important;
    -moz-transition-property: none !important;
    -ms-transition-property: none !important;
    -webkit-transition-property: none !important;
    transition-property: none !important;
    /*CSS transforms*/
    -o-transform: none !important;
    -moz-transform: none !important;
    -ms-transform: none !important;
    -webkit-transform: none !important;
    transform: none !important;
    /*CSS animations*/
    -webkit-animation: none !important;
    -moz-animation: none !important;
    -o-animation: none !important;
    -ms-animation: none !important;
    animation: none !important;
  }

  .dropzone-container {
    display: none;
    border: 0;
    transition: all 0.2s;
  }

  .dropzone-container.visible {
    display: block;
  }

  .remove-checkbox {
    float: right;
  }

  .type-select {
    display: block;
    text-align: center;
    margin-top: 5px;
  }

  .ep-heading {
    margin-bottom: 20px;
  }

  .download-icon {
    position: absolute;
    right: 35px;
    bottom: 9px;
  }
</style>
