<template>
  <div class="modal" ref="modal" :style="{ display }">

    <div class="cont">
      <!-- The Close Button -->
      <span @click="hide" class="close">&times;</span>

      <!-- The Previous Picture Button -->
      <span @click="previous" class="previous" v-if="!singlePictureMode && picture.order !== 0">
        <span class="previous-icon">&larr;</span>
      </span>

      <!-- The Next Picture Button -->
      <span @click="next" class="next" v-if="!singlePictureMode && picture.order !== highestOrder">
        <span class="next-icon">&rarr;</span>
      </span>

      <!-- Modal Content (The Image) -->
      <img class="modal-content" id="img01" :src="picture.url_optimized || picture.url">
    </div>

    <!-- Modal Caption (Image Text) -->
    <div class="tw-my-4 tw-mx-auto tw-text-center tw-max-w-lg">
      <p>
        <span v-if="picture.is_main_picture" class="chip tw-text-white tw-bg-success">Hoofdfoto</span>
        <span
          :class="[
            'chip tw-text-white tw-cursor-pointer hover:tw-shadow-xl disabled:tw-bg-gray-cc',
            picturePrivacy.color
          ]"
        >
          <input
            v-model="picture.public"
            type="checkbox"
            name="public"
            id="privacy-toggle"
            class="tw-inline-block !tw-mr-1 !tw-my-0"
            :disabled="togglingPrivacy"
            @change="togglePrivacy(picture.id)"
          />
          <label
            for="privacy-toggle"
            :title="picturePrivacy.title"
            class="tw-my-0 tw-font-normal tw-cursor-pointer"
          >
            <i :class="picturePrivacy.icon" /> {{ picturePrivacy.text }}
          </label>
        </span>
      </p>
      <p>
        <span
          v-for="tag in picture.tags"
          :key="tag"
          class="chip tw-bg-gray-cc"
        >
          {{ tag }}
        </span>
      </p>
    </div>
  </div>
</template>

<script>
import debounce from 'lodash/debounce'

import { successModal, errorModal } from '@/modalMessages'
import { togglePropertyPicturePrivacy } from '@/services/properties'
import { toggleProjectPicturePrivacy } from '@/services/projects'

export default {
  name: 'PicturePopUp',
  props: {
    propertyId: {
      type: Number
    },
    projectId: {
      type: Number
    },
    pictures: {
      type: Array,
      default: () => []
    },
    singlePictureMode: {
      required: false, // true = Show arrows to move on to next photo. false = Show only chosen picture and no arrows
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      display: 'none',
      picture: {
        url: '',
        tags: [],
        order: 0,
        public: false,
        is_main_picture: false
      },
      togglingPrivacy: false
    }
  },

  methods: {
    show (pictureId) {
      this.picture = this.pictures.find(pic => pic.id === pictureId)
      this.display = 'block'
    },
    hide () {
      this.display = 'none'
    },
    next () {
      const pic = this.pictures?.find(pic => pic.order === this.picture.order + 1)
      if (pic === undefined) {
        this.picture = this.pictures?.filter(p => p.order > this.picture.order).sort((a, b) => a.order - b.order)[0]
      } else {
        this.picture = pic
      }
    },
    previous () {
      const pic = this.pictures?.find(pic => pic.order === this.picture.order - 1)
      if (pic === undefined) {
        this.picture = this.pictures?.filter(p => p.order < this.picture.order).sort((a, b) => b.order - a.order)[0]
      } else {
        this.picture = pic
      }
    },
    togglePrivacy: debounce(
      async function (pictureId) {
        try {
          this.togglingPrivacy = true
          const response = this.propertyId
            ? await togglePropertyPicturePrivacy(this.propertyId, pictureId)
            : await toggleProjectPicturePrivacy(this.projectId, pictureId)
          this.picture = response.data
          this.togglingPrivacy = false
          this.$emit('reloadPictures')
          successModal('De privacystatus is gewijzigd.')
          return response
        } catch (error) {
          errorModal('Fout bij wijzigen van privacystatus, probeer het opnieuw.')
        }
      },
      200,
      { maxWait: 2000 }
    )
  },
  computed: {
    highestOrder () {
      if (!this.singlePictureMode && this.picture && this.pictures.map) {
        return Math.max.apply(Math, this.pictures.map(function (o) {
          return o.order
        }))
      }
      return 0
    },
    picturePrivacy () {
      const isPublic = this.picture?.public
      const title = `Foto ${isPublic ? 'privé' : 'openbaar'} maken`
      const icon = `far ${isPublic ? 'fa-eye' : 'fa-eye-slash'}`
      const text = isPublic ? 'Publiek' : 'Privé'
      const color = isPublic ? 'tw-bg-primary' : 'tw-bg-tg-color'

      return { title, icon, text, color }
    }
  }
}
</script>

<style scoped>
.chip {
  @apply tw-px-2 tw-py-1 tw-mx-1 tw-my-0 tw-text-sm tw-rounded-sm
}

/* The Modal (background) */
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.7); /* Black w/ opacity */
}

/* Modal Content (Image) */
.modal-content {
  margin: auto;
  display: block;
  width: auto;
  height: 80vh;
  max-width: 60%;
}

/* Add Animation - Zoom in the Modal */
.modal-content {
  animation-name: zoom;
  animation-duration: 0.4s;
}

@keyframes zoom {
  from {
    transform: scale(0)
  }
  to {
    transform: scale(1)
  }
}

.cont {
  position: relative;
  display: inline-block;
  left: 50%;
  transform: translate(-50%);
  width: 100%;
}

/* The Close Button */
.close {
  top: -4%;
  right: 18%;
  font-size: 40px;
}

.previous {
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.next {
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.cont span:not(.close) {
  font-size: 60px;
}

.previous,
.next {
  height: 100%;
  width: 20%;
}

.next-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.previous-icon {
  float: right;
  top: 50%;
  position: absolute;
  right: 1%;
  transform: translateY(-50%);
}

.close,
.previous,
.next {
  position: absolute;
  color: #f1f1f1;
  font-weight: bold;
  transition: 0.3s;
  opacity: .7;
}

.close:hover,
.close:focus,
.previous:hover,
.previous:focus,
.next:hover,
.next:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}

/* 100% Image Width on Smaller Screens */
@media only screen and (max-width: 700px) {
  .modal-content {
    width: 100%;
  }
}
</style>
