<template>
  <div>
    <PanelBox title="Foto's" :isCollapsible="true">
      <EntityPictures />
    </PanelBox>
    <PanelBox title="Andere" :isCollapsible="true">
      <EntityMedia />
    </PanelBox>
  </div>
</template>

<script>
import PanelBox from '@/components/iam/PanelBox'
import EntityMedia from '@/components/properties/EntityMedia'
import EntityPictures from '@/components/properties/EntityPictures'

export default {
  name: 'MediaPanel',
  components: {
    PanelBox,
    EntityMedia,
    EntityPictures
  }
}
</script>
